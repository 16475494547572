<template>
  <div class="page-common page-discover-tab">
    <div class="page-params">
      <el-select
        clearable
        size="small"
        class="param-item"
        v-model="params.status"
        placeholder="选项"
        @change="initPageData"
      >
        <el-option label="已发布" value="published" />
        <el-option label="草稿" value="draft" />
      </el-select>
      <el-button size="small" class="param-item" icon="el-icon-plus" @click="addTab">新增</el-button>
      <el-button
        size="small"
        type="primary"
        class="param-item"
        icon="el-icon-sort"
        @click="submitSort"
      >提交调序</el-button>
    </div>

    <div style="padding-bottom: 10px">
      <h3 class="mind-tip">(拖动)排序 提交后生效</h3>
      <Draggable v-model="tabList" item-key="id">
        <template #item="{ element }">
          <el-tag class="drag-tag">{{element.id}} {{ element?.tab_name }}</el-tag>
        </template>
      </Draggable>
    </div>

    <el-table
      size="medium"
      ref="table"
      element-loading-spinner="el-icon-loading"
      v-loading="tabLoading"
      :data="tabList"
    >
      <el-table-column align="center" label="ID" prop="id" width="60"></el-table-column>
      <el-table-column align="center" label="tab名称" min-width="160">
        <template #default="{row}">
          <img
            class="table-img"
            v-if="row.tab_background_url"
            :src="row.tab_background_url"
            alt="xgb"
          />
          <span v-else class="mark">{{row.tab_name}}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="tabURL" min-width="240">
        <template #default="{row}">
          <div>{{row.tab_url}}</div>
          <div class="mark">{{row.event_name}}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="tab提醒类型">
        <template #default="{row}">
          <span v-if="row.badge_image_url">有</span>
          <span v-else>无</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="状态">
        <template #default="{row}">
          <span class="mark" v-if="row.status === 'published'">已发布</span>
          <span v-else>未发布</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="平台">
        <template #default="{row}">
          <span>{{row.platform || '全部'}}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="最低适用版本" prop="min_version"></el-table-column>
      <el-table-column align="center" label="有效区间">
        <template #default="{row}">
          <template v-if="row.begin_at || row.end_at">
            <span v-if="row.begin_at > 0">{{$filters.formatDate(row.begin_at, 'YYYY-MM-DD HH:mm')}}</span>
            <span v-else>--</span>
            <div>至</div>
            <span v-if="row.end_at > 0">{{$filters.formatDate(row.end_at, 'YYYY-MM-DD HH:mm')}}</span>
            <span v-else>--</span>
          </template>
          <template v-else>
            <span>长期有效</span>
          </template>
        </template>
      </el-table-column>
      <el-table-column align="center" label="最后修改人">
        <template #default="{row}">
          <span v-if="UserById[row.last_modify_id]">{{UserById[row.last_modify_id].display_name}}</span>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" min-width="132">
        <template #default="{row}">
          <el-button size="mini" @click="handleEditTab(row)">编辑</el-button>
          <el-button
            v-if="row.status !== 'published'"
            size="mini"
            type="success"
            @click="handleUpdateStatus(row, 'published')"
          >发布</el-button>
          <el-button
            v-if="row.status !== 'draft'"
            size="mini"
            type="danger"
            @click="handleUpdateStatus(row, 'draft')"
          >撤回</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      title="编辑"
      custom-class="large-modal"
      v-model="editingVisible"
      :close-on-click-modal="false"
      @close="editingVisible = false"
    >
      <el-form
        ref="tabForm"
        label-position="right"
        label-width="132px"
        :rules="rules_tab"
        :model="editingTab"
      >
        <el-form-item label="tab类型">
          <el-radio-group v-model="editingTab.media_type">
            <el-radio label="text">文字</el-radio>
            <el-radio label="img">图片</el-radio>
          </el-radio-group>
        </el-form-item>
        <template v-if="editingTab.media_type === 'text'">
          <el-form-item prop="tab_name" label="tab展示名称">
            <el-input type="text" v-model.trim="editingTab.tab_name" placeholder="请输入"></el-input>
          </el-form-item>
        </template>
        <template v-if="editingTab.media_type === 'img'">
          <el-form-item prop="tab_background_url" label="tab图片">
            <UploadQiniu v-model="editingTab.tab_background_url" />
          </el-form-item>
          <el-form-item prop="tab_background_url_selected" label="tab选中图片">
            <UploadQiniu v-model="editingTab.tab_background_url_selected" />
          </el-form-item>
        </template>
        <el-form-item prop="tab_url" label="tabURL">
          <el-input type="text" v-model.trim="editingTab.tab_url" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item prop="badge_image_url" label="角标图片">
          <UploadQiniu v-model="editingTab.badge_image_url" />
        </el-form-item>
        <el-form-item prop="badge_type" label="角标类型">
          <el-select v-model="editingTab.badge_type" placeholder="请选择">
            <el-option label="默认" value="default" />
            <el-option label="永久" value="permanent" />
            <el-option label="短暂" value="ephemeral" />
          </el-select>
        </el-form-item>
        <el-form-item prop="platform" label="平台">
          <el-select v-model="editingTab.platform" placeholder="请选择">
            <el-option label="全部" :value="''" />
            <el-option label="ios" value="ios" />
            <el-option label="android" value="android" />
          </el-select>
        </el-form-item>
        <el-form-item prop="min_version" label="最低版本">
          <el-input type="text" v-model.trim="editingTab.min_version" placeholder="请输入"></el-input>
          <span class="mark">格式：5.1.2</span>
        </el-form-item>
        <el-form-item label="有效区间">
          <el-date-picker
            style="width: 500px"
            v-model="betweenTime"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item prop="event_name" label="打点名称">
          <el-input type="text" v-model.number="editingTab.event_name" placeholder="请输入"></el-input>
          <span class="mark">格式：Home_事件名_Click</span>
        </el-form-item>
        <el-form-item prop="tab_key" label="tab_key">
          <el-select v-model="editingTab.tab_key" placeholder="请选择">
            <el-option label="home_featured" value="home_featured" />
            <el-option label="home_recommend" value="home_recommend" />
            <el-option label="home_following" value="home_following" />
            <el-option label="home_live" value="home_live" />
            <el-option label="home_premium" value="home_premium" />
            <el-option label="home_course" value="home_course" />
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button size="small" type="primary" @click="commonModalConfirm">确 定</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import cloneDeep from 'lodash/cloneDeep'
import Draggable from 'vuedraggable'
import UploadQiniu from '@/components/base/upload-qiniu'
import { fetchDiscoverTabs, addDiscoverTab, updateDiscoverTab } from '@/domains/base-baoer'

const mockTab = {
  badge_id: '',
  badge_image_url: '',
  badge_key: '',
  badge_type: 'default',
  media_type: 'text',
  begin_at: 0,
  end_at: 0,
  event_name: 'Home_EventName_Click',
  id: 0,
  last_modify_id: 0,
  max_version: '',
  min_version: '',
  order: 1,
  platform: '',
  status: 'published',
  tab_background_url: '',
  tab_background_url_selected: '',
  tab_key: '',
  tab_name: '',
  tab_url: 'https://xuangubao.cn/discover/'
}

const getTabFormRules = (ctx) => {
  const check_tab_name = (rule, value, callback) => {
    if (ctx.editingTab.media_type === 'text' && !value) {
      callback(new Error('请填写名称'))
    } else {
      callback()
    }
  }
  const check_min_version = (rule, value, callback) => {
    if (value && !/^\d{1,2}\.\d{1,2}.\d{1,2}$/.test(value)) {
      callback(new Error('最低版本格式不规范'))
    } else {
      callback()
    }
  }
  const check_event_name = (rule, value, callback) => {
    if (value && !/^Home_.+_Click$/.test(value)) {
      callback(new Error('打点名称格式不规范'))
    } else {
      callback()
    }
  }
  const check_tab_background = (rule, value, callback) => {
    if (ctx.editingTab.media_type === 'img' && !value) {
      callback(new Error('请上传图片'))
    } else {
      callback()
    }
  }
  return {
    tab_name: [{ validator: check_tab_name }],
    tab_url: [{ required: true, message: 'tabURL必填' }],
    tab_key: [{ required: true, message: 'tab_key必填' }],
    min_version: [{ validator: check_min_version }],
    event_name: [{ validator: check_event_name }],
    tab_background_url_selected: [{ validator: check_tab_background }],
    tab_background_url: [{ validator: check_tab_background }]
  }
}

export default {
  name: 'DiscoverTab',
  components: { UploadQiniu, Draggable },
  data() {
    this.rules_tab = getTabFormRules(this)
    return {
      params: {
        status: ''
      },
      tabList: [],
      tabLoading: false,
      editingVisible: false,
      editingTab: cloneDeep(mockTab),
      UserById: {}
    }
  },
  computed: {
    betweenTime: {
      set(val) {
        if (!val) {
          Object.assign(this.editingTab, {
            begin_at: 0,
            end_at: 0
          })
          return
        }
        const [start, end] = val
        Object.assign(this.editingTab, {
          begin_at: start.getTime() / 1000,
          end_at: end.getTime() / 1000
        })
      },
      get() {
        const { begin_at, end_at } = this.editingTab
        if (!begin_at && !end_at) {
          return null
        }
        return [new Date(begin_at * 1000), new Date(end_at * 1000)]
      }
    }
  },
  mounted() {
    this.initPageData(true)
  },
  methods: {
    initPageData(is_init) {
      if (is_init) this.tabLoading = true
      fetchDiscoverTabs(this.params)
        .then((data) => {
          if (data.GetFindTabConfigResp && data.GetFindTabConfigResp.items) {
            this.tabList = data.GetFindTabConfigResp.items
          } else {
            this.tabList = []
          }
          if (data.UserById) {
            this.UserById = data.UserById
          }
        })
        .finally(() => {
          this.tabLoading = false
        })
    },
    addTab() {
      this.editingTab = cloneDeep(mockTab)
      this.editingVisible = true
    },
    handleEditTab(row) {
      console.log('edit', row.id)
      this.editingVisible = true
      if (row.tab_background_url) {
        row.media_type = 'img'
      } else {
        row.media_type = 'text'
      }
      this.editingTab = cloneDeep(row)
    },
    handleUpdateStatus(row, status) {
      console.log(status, row.id)
      updateDiscoverTab({ items: [Object.assign({}, row, { status })] })
        .then(() => {
          this.$message.success(status === 'published' ? '已发布' : '已撤回')
          this.initPageData()
        })
        .catch((err) => {
          this.$message.error(JSON.stringify(err))
        })
    },
    commonModalConfirm() {
      this.$refs.tabForm.validate((res) => {
        if (!res) return
        const body = this.editingTab
        if (body.media_type === 'text') {
          body.tab_background_url = ''
          body.tab_background_url_selected = ''
        }
        console.log(JSON.stringify(body, null, 2))
        if (body.id) {
          updateDiscoverTab({ items: [body] })
            .then(() => {
              this.$message.success('已保存')
              this.initPageData()
              this.editingVisible = false
            })
            .catch((err) => {
              this.$message.error(JSON.stringify(err))
            })
        } else {
          body.order = this.tabList.length + 1
          addDiscoverTab({ items: [body] })
            .then(() => {
              this.$message.success('已添加')
              this.initPageData()
              this.editingVisible = false
            })
            .catch((err) => {
              this.$message.error(JSON.stringify(err))
            })
        }
      })
    },
    submitSort() {
      const body = {
        items: this.tabList.map((tab, idx) => {
          tab.order = idx + 1
          return tab
        })
      }
      updateDiscoverTab(body)
        .then(() => {
          this.$message.success('已保存排序')
          this.initPageData()
          this.editingVisible = false
        })
        .catch((err) => {
          this.$message.error(JSON.stringify(err))
        })
    }
  }
}
</script>
<style lang="scss">
.page-discover-tab {
  position: relative;
  width: calc(100% - 6px);
  .mind-tip {
    font-size: 13px;
    padding-bottom: 6px;
  }
  .table-img {
    display: block;
    height: auto;
    min-width: 160px;
  }
}
</style>
